import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Login = () => import('../components/Login')
const Authorize = () => import('../components/Authorize')
const UserInfo = () => import('../components/UserInfo')

const router = new VueRouter({
  mode: 'history',
  routes:[
    { name: 'autherize', path: '/authorize', component: Authorize },
    { name: 'login', path: '/login', component: Login },
    { name: 'userInfo', path: '/login', component: UserInfo }
  ]
})
export default router